html,
body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.out-button {
  text-transform: capitalize;
}
