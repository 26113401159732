@import "../../styles/_colors.scss";

.app-container {
  min-height: calc(100vh);
}

.landing {
  background-image: url("../../images/MaltedMainImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
